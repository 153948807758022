import { Box, Typography } from "@mui/material";
import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { confirmPasswordReset } from "firebase/auth";
import { AUTH_LOGIN } from '../../../Config/firebase';
import LOGIN_BG from '../../../Assets/Images/Common/loginBg.png';
import CustomTextField from "../../../Components/CustomTextField/CustomTextField";
import CustomSubmitButton from "../../../Components/CustomSubmitButton/CustomSubmitButton";
const ResetPassword = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const queryParams = new URLSearchParams(location?.search);
    const oobCode = queryParams.get("oobCode");
    const formik = useFormik({
        initialValues: {
            newPassword: "",
            confirmPassword: "",
        },
        validationSchema: Yup.object().shape({
            newPassword: Yup.string()
                .required("New password is required!")
                .matches(
                    /^(?=.*[a-zA-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
                    "Password must contain at least one letter, one number, and one symbol. Minimum length is 8 characters."
                ),
            confirmPassword: Yup.string()
                .required("Confirm password is required!")
                .oneOf([Yup.ref('newPassword')], 'Confirm Password must match')
        }),
        onSubmit: async (values, { resetForm }) => {
            setIsLoading(true);
            navigate('/login')
        },
    });
    return (
        <>
            <Box
                sx={{
                    background: "linear-gradient(167.96deg, #353535 0%, #000000 100%)",
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "100% 100%",
                }}
            >
                <Box
                    sx={{
                        width: "100%",
                        minHeight: "100vh",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        py: 2,
                    }}
                >
                    <Box
                        sx={{
                            width: { xs: "95%", sm: "60%", md: "50%", lg: "40%", xl: "30%" },

                        }}
                    >
                        <Box
                            sx={{
                                width: "100%",
                                border: "3px solid rgba(42, 245, 152, 1)",
                                // box-shadow: 0px 4px 20.8px 0px #00000040;
                                backgroundColor: '#00000040',
                                borderRadius: "30px",
                                py: 1,
                                position: "relative",
                                zIndex: "3",
                                boxShadow: '0px 4px 20.8px 0px #00000040',
                                opacity: 1
                            }}
                        >
                            <Box sx={{ height: "100%", width: "100%", p: 3 }}>
                                <Typography sx={{ fontSize: "35px", fontWeight: "600", color: '#fff', fontFamily: "Outfit", }}>
                                    Reset Password!
                                </Typography>
                                <Typography sx={{ fontSize: "14px", fontWeight: "400", color: '#fff', fontFamily: "Outfit", }}>
                                    Lorem ipsum dolor sit amet consectetur adipiscing elit
                                    tortor eu dolorol egestas morbi sem vulputate etiam
                                    facilisis pellentesque ut quis.
                                </Typography>

                                <form
                                    onSubmit={(e) => {
                                        e.preventDefault();
                                        formik.handleSubmit();
                                        return false;
                                    }}
                                    style={{
                                        width: "100%",

                                        display: "flex",
                                        flexDirection: "column",
                                        justifyContent: "start",
                                        alignItems: "center",
                                        gap: "1rem",
                                        paddingTop: "30px",
                                    }}
                                >
                                    <Box sx={{ width: "100%" }}>
                                        <CustomTextField
                                            label="New Password"
                                            type="password"
                                            placeholder="New Password"
                                            name="newPassword"
                                            value={formik.values.newPassword}
                                            onBlur={formik.handleBlur}
                                            onKeyUp={formik.handleBlur}
                                            errors={formik.touched.newPassword && formik.errors.newPassword}
                                            onChange={(e) => {
                                                const { value } = e.target;
                                                if (value.trimStart() !== value) {
                                                    e.target.value = value.trimStart();
                                                }
                                                formik.handleChange(e);
                                            }}
                                        />
                                    </Box>
                                    <Box sx={{ width: "100%" }}>
                                        <CustomTextField
                                            label="Confirm Pasword"
                                            type="password"
                                            placeholder="Confirm Password"
                                            name="confirmPassword"
                                            value={formik.values.confirmPassword}
                                            onBlur={formik.handleBlur}
                                            onKeyUp={formik.handleBlur}
                                            errors={
                                                formik.touched.confirmPassword && formik.errors.confirmPassword
                                            }
                                            onChange={(e) => {
                                                const { value } = e.target;
                                                if (value.trimStart() !== value) {
                                                    e.target.value = value.trimStart();
                                                }
                                                formik.handleChange(e);
                                            }}
                                        />
                                    </Box>
                                    <Box
                                        sx={{
                                            width: "55%",
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                        }}
                                    >
                                        <CustomSubmitButton
                                            btnTitle="Reset Password"
                                            loading={isLoading}
                                            type="submit"
                                        />
                                    </Box>
                                </form>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </>
    );
};

export default ResetPassword;
