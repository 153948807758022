import { Box, Button, Typography } from "@mui/material";
import React, { useState } from "react";
import CustomSearch from "../../../Components/CustomSearch/CustomSearch";
import CustomDropDown from "../../../Components/CustomDropDown/CustomDropDown";
import TableContainer from "../../../Components/TableContainer/TableContainer";
import CustomAction from "../../../Components/CustomAction/CustomAction";
import Document from "../../../Assets/Images/Common/updated-aadhaar.png";
import { useNavigate } from "react-router-dom";
const MonthlyGraph = () => {
  const navigate = useNavigate()
  const pageSize = 30;
  const [category, setCategory] = useState(0);
  const [monthlyGraphList, setMonthlyGraphList] = useState([]);
  const [page, setPage] = useState(0);
  const [count, setCount] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const userCategory = [
    {
      id: 1,
      name: "All",
    },
    {
      id: 2,
      name: "Individual Customer",
    },
    {
      id: 3,
      name: "Corporate Customer",
    },
    {
      id: 4,
      name: "Test Customer",
    },
  ];
  const columns = [
    { field: "rowid", headerName: "Sr.No.", minWidth: 100 },
    {
      field: "name",
      headerName: "Name",
      minWidth: 200,
    },
    {
      field: "phone",
      headerName: "Phone Number",
      minWidth: 200,
    },
    {
      field: "email",
      headerName: "Email",
      flex: 1,
      minWidth: 200,
    },
    {
      field: "joining_date",
      headerName: "Joining Date",
      minWidth: 200,
    },
    {
      field: "return",
      headerName: "Return",
      minWidth: 200,
    },
    {
      field: "action",
      headerName: "Actions",
      minWidth: 200,
      renderCell: (params) => {
        return (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: "100%",
              gap: "2rem",
            }}
          >
            <CustomAction
              showDelete={true}
              showEdit={true}
              showView={true}
            />
          </Box>
        );
      },
    },
  ];
  const handlePageChange = (newPage) => {
    setPage(newPage);
  };
  return (
    <>
      <Box
        sx={{
          width: "100%",
          bgcolor: "rgb(230, 247, 234)",
        }}
      >
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography sx={{ fontSize: "24px", fontWeight: "500" }}>
            Monthly Graph Details
          </Typography>
          <Button
            onClick={() => navigate("/admin/create-monthly-graph")}
            sx={{
              bgcolor: "#6ee498",
              color: "black",
              "&:hover": { background: "#6ee498", color: "black" },
            }}
          >
            + Create Monthly Graph
          </Button>
        </Box>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "end",
            py: 2,
          }}
        >
          <Box sx={{ width: "100%" }}>
            <CustomSearch placeholder={"Search"} style={{ maxWidth: "350px" }} />
          </Box>
        </Box>
        <Box
          sx={{
            width: "100%",
          }}
        >
          <TableContainer
            rows={monthlyGraphList}
            columns={columns}
            pageSize={pageSize}
            loading={isLoading}
            page={page}
            totalCount={count}
            handlePageChange={handlePageChange}
          />
        </Box>
      </Box>
    </>
  );
};

export default MonthlyGraph;
