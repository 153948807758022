import { Box, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
// import { signInWithEmailAndPassword } from "firebase/auth";
// import {AUTH_LOGIN} from '../../../Config/firebase';
import AxiosInstance from "../../../Config/AxiosInstance";
import { APILIST } from "../../../Config/ApiList";
import LOGIN_BG from '../../../Assets/Images/Common/loginBg.png';
import CustomTextField from "../../../Components/CustomTextField/CustomTextField";
import CustomSubmitButton from "../../../Components/CustomSubmitButton/CustomSubmitButton";
import Constant from "../../../Utils/Constant";
const Login = () => {
  // const location = useLocation();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  // useEffect(() => {
  //   const userCheck = JSON.parse(localStorage.getItem("TRANSFORMATIONAL_RUNNER"));
  //   if (userCheck) {
  //     if (userCheck?.role === 2) navigate("/admin/dashboard");
  //   }
  // }, [location, navigate]);
  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: Yup.object().shape({
      email: Yup.string().required("Email Address Is Required!").matches(Constant.REGEX.EMAIL, "Please Provide Valid Email Address!"),
      password: Yup.string()
        .required("Password Is Required!")
        .matches(
          /^(?=.*[a-zA-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
          "Password must contain at least one letter, one number, and one symbol. Minimum length is 8 characters."
        )
    }),
    onSubmit: async (values, { resetForm }) => {
      navigate('/admin/dashboard')
    },
  });
  return (
    <>
      <Box
        sx={{
          background: "linear-gradient(167.96deg, #353535 0%, #000000 100%)",
          backgroundRepeat: "no-repeat",
          backgroundSize: "100% 100%",
        }}
      >
        <Box
          sx={{
            width: "100%",
            minHeight: "100vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            py: 2,
          }}
        >
          <Box
            sx={{
              width: { xs: "95%", sm: "60%", md: "50%", lg: "40%", xl: "30%" },

            }}
          >
            <Box
              sx={{
                width: "100%",
                border: "3px solid rgba(42, 245, 152, 1)",
                // box-shadow: 0px 4px 20.8px 0px #00000040;
                backgroundColor: '#00000040',
                borderRadius: "30px",
                py: 1,
                position: "relative",
                zIndex: "3",
                boxShadow: '0px 4px 20.8px 0px #00000040',
                opacity: 1
              }}
            >
              <Box sx={{ height: "100%", width: "100%", p: 3 }}>
                <Typography sx={{ fontSize: "35px", fontWeight: "600", color: '#fff', fontFamily: "Outfit", }}>
                  Welcome Back!
                </Typography>
                <Typography sx={{ fontSize: "14px", fontWeight: "400", color: '#fff', fontFamily: "Outfit", }}>

                  Please, insert your details to login.
                </Typography>

                <form
                  onSubmit={(e) => {
                    e.preventDefault();
                    formik.handleSubmit();
                    return false;
                  }}
                  style={{
                    width: "100%",

                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "start",
                    alignItems: "center",
                    gap: "1rem",
                    paddingTop: "30px",
                  }}
                >
                  <Box sx={{ width: "100%" }}>
                    <CustomTextField
                      label="Email"
                      type="text"
                      placeholder="Email Address"
                      name="email"
                      value={formik.values.email}
                      onBlur={formik.handleBlur}
                      onKeyUp={formik.handleBlur}
                      errors={formik.touched.email && formik.errors.email}
                      onChange={(e) => {
                        const { value } = e.target;
                        if (value.trimStart() !== value) {
                          e.target.value = value.trimStart();
                        }
                        formik.handleChange(e);
                      }}
                    />
                  </Box>
                  <Box sx={{ width: "100%" }}>
                    <CustomTextField
                      label="Password"
                      type="password"
                      placeholder="Password"
                      name="password"
                      value={formik.values.password}
                      onBlur={formik.handleBlur}
                      onKeyUp={formik.handleBlur}
                      errors={
                        formik.touched.password && formik.errors.password
                      }
                      onChange={(e) => {
                        const { value } = e.target;
                        if (value.trimStart() !== value) {
                          e.target.value = value.trimStart();
                        }
                        formik.handleChange(e);
                      }}
                    />
                  </Box>
                  <Typography
                    style={{
                      width: "100%",
                      textAlign: "right",
                      fontSize: "16px",
                      color: '#fff',
                      textDecoration: "none",
                      cursor: 'pointer',
                      fontWeight: "500",
                      fontFamily: "Outfit",
                      "&:hover": {
                        textDecoration: "underline",
                      }
                    }}
                    onClick={() => navigate('/forgot-password')}
                  >
                    Forgot Password?
                  </Typography>
                  <Box
                    sx={{
                      width: "55%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <CustomSubmitButton
                      btnTitle="Submit"
                      loading={isLoading}
                      type="submit"
                    />
                  </Box>
                </form>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default Login;
