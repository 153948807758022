import React from 'react'

const AboutUs = () => {
  return (
    <div>
      AboutUs
    </div>
  )
}

export default AboutUs
