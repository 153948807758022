import { Box, Typography } from "@mui/material";
import * as React from "react";
import Avatar from "@mui/material/Avatar";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import Settings from "@mui/icons-material/Settings";
import Logout from "@mui/icons-material/Logout";
import GroupIcon from "@mui/icons-material/Group";
import PersonIcon from "@mui/icons-material/Person";
import GroupsIcon from "@mui/icons-material/Groups";
import WalletIcon from "@mui/icons-material/Wallet";
import AddCardIcon from "@mui/icons-material/AddCard";
import LineChart from "../../../Components/DashboardChart/LineChart";
const Dashboard = () => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  function formatNumber(num) {
    if (num >= 1_000_000_000) {
      return (num / 1_000_000_000).toFixed(1) + "B"; // Billions
    } else if (num >= 1_000_000) {
      return (num / 1_000_000).toFixed(1) + "M"; // Millions
    } else if (num >= 1_000) {
      return (num / 1_000).toFixed(1) + "K"; // Thousands
    } else {
      return num.toString(); // Less than 1K
    }
  }
  return (
    <>
      <Box
        sx={{
          width: "100%",
          bgcolor: "rgb(230, 247, 234)",
        }}
      >
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "start",
          }}
        >
          <Typography sx={{ fontSize: "24px", fontWeight: "500" }}>
            Dashboard
          </Typography>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              textAlign: "center",
              position: "relative",
            }}
          >
            <Tooltip title="Account settings">
              <IconButton
                onClick={handleClick}
                size="small"
                sx={{ ml: 2 }}
                aria-controls={open ? "account-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
              >
                <Avatar sx={{ width: 45, height: 45 }}>P</Avatar>
              </IconButton>
            </Tooltip>
            <Menu
              anchorEl={anchorEl}
              id="account-menu"
              open={open}
              onClose={handleClose}
              onClick={handleClose}
              slotProps={{
                paper: {
                  elevation: 0,
                  sx: {
                    overflow: "visible",
                    filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                    width: "200px",
                    "& .MuiAvatar-root": {
                      width: 32,
                      height: 32,
                      ml: -0.5,
                      mr: 1,
                    },
                    "&::before": {
                      content: '""',
                      display: "block",
                      position: "absolute",
                      top: 0,
                      right: 14,
                      width: 10,
                      height: 10,
                      bgcolor: "background.paper",
                      transform: "translateY(-50%) rotate(45deg)",
                      zIndex: 0,
                    },
                  },
                },
              }}
              transformOrigin={{ horizontal: "right", vertical: "top" }}
              anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
            >
              <MenuItem onClick={handleClose}>
                <Avatar /> Profile
              </MenuItem>
              <Divider />

              <MenuItem onClick={handleClose}>
                <ListItemIcon>
                  <Settings fontSize="small" />
                </ListItemIcon>
                Settings
              </MenuItem>
              <MenuItem onClick={handleClose}>
                <ListItemIcon>
                  <Logout fontSize="small" />
                </ListItemIcon>
                Logout
              </MenuItem>
            </Menu>
          </Box>
        </Box>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            flexWrap: "wrap",
            py: 2,
            gap: "20px",
          }}
        >
          <Box sx={{ width: "300px" }}>
            <Box
              sx={{
                width: "100%",
                borderRadius: "12px",
                bgcolor: "white",
                p: 2,
              }}
            >
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "start",
                  gap: "10px",
                  alignItems: "center",
                }}
              >
                <GroupsIcon style={{ fontSize: "28px", color: "#fffff" }} />
                <Typography sx={{ fontSize: "20px" }}>Total Users</Typography>
              </Box>
              <Typography sx={{ pt: 1, fontSize: "24px", fontWeight: "500" }}>
                {formatNumber("50")}
              </Typography>
            </Box>
          </Box>
          <Box sx={{ width: "300px" }}>
            <Box
              sx={{
                width: "100%",
                borderRadius: "12px",
                bgcolor: "white",
                p: 2,
              }}
            >
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "start",
                  gap: "10px",
                  alignItems: "center",
                }}
              >
                <GroupIcon style={{ fontSize: "28px", color: "#fffff" }} />
                <Typography sx={{ fontSize: "20px" }}>
                  Total Corporate Users
                </Typography>
              </Box>
              <Typography sx={{ pt: 1, fontSize: "24px", fontWeight: "500" }}>
                {formatNumber("50")}
              </Typography>
            </Box>
          </Box>
          <Box sx={{ width: "300px" }}>
            <Box
              sx={{
                width: "100%",
                borderRadius: "12px",
                bgcolor: "white",
                p: 2,
              }}
            >
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "start",
                  gap: "10px",
                  alignItems: "center",
                }}
              >
                <PersonIcon style={{ fontSize: "28px", color: "#fffff" }} />
                <Typography sx={{ fontSize: "20px" }}>
                  Total Individual Users
                </Typography>
              </Box>
              <Typography sx={{ pt: 1, fontSize: "24px", fontWeight: "500" }}>
                {formatNumber("50")}
              </Typography>
            </Box>
          </Box>
          <Box sx={{ width: "300px" }}>
            <Box
              sx={{
                width: "100%",
                borderRadius: "12px",
                bgcolor: "white",
                p: 2,
              }}
            >
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "start",
                  gap: "10px",
                  alignItems: "center",
                }}
              >
                <AddCardIcon style={{ fontSize: "28px", color: "#fffff" }} />
                <Typography sx={{ fontSize: "20px" }}>
                  Total Deposits
                </Typography>
              </Box>
              <Typography sx={{ pt: 1, fontSize: "24px", fontWeight: "500" }}>
                ${formatNumber("500000")}
              </Typography>
            </Box>
          </Box>
          <Box sx={{ width: "300px" }}>
            <Box
              sx={{
                width: "100%",
                borderRadius: "12px",
                bgcolor: "white",
                p: 2,
              }}
            >
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "start",
                  gap: "10px",
                  alignItems: "center",
                }}
              >
                <WalletIcon style={{ fontSize: "28px", color: "#fffff" }} />
                <Typography sx={{ fontSize: "20px" }}>
                  Total Withdrawals
                </Typography>
              </Box>
              <Typography sx={{ pt: 1, fontSize: "24px", fontWeight: "500" }}>
                ${formatNumber("50000")}
              </Typography>
            </Box>
          </Box>
        </Box>
        <Box sx={{width:"100%",py:5,width:"100%",display:"flex",justifyContent:'center',flexDirection:'column',alignItems:'center'}}>
          <Typography sx={{fontSize:"22px",fontWeight:"500"}}>Every year’s Performance of Deposit and
          Withdrawn</Typography>
          <LineChart />
        </Box>
      </Box>
    </>
  );
};

export default Dashboard;