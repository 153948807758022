import React, { useRef, useEffect } from 'react';
import { Chart, LineElement, PointElement, LineController, CategoryScale, LinearScale, Title, Tooltip, Legend } from 'chart.js';

Chart.register(LineElement, PointElement, LineController, CategoryScale, LinearScale, Title, Tooltip, Legend);

const LineChart = () => {
  const chartRef = useRef(null);

  useEffect(() => {
    const ctx = chartRef.current.getContext('2d');

    const data = {
      labels: ['2004', '2005', '2006', '2007', '2008'],
      datasets: [
        {
          label: 'Dataset',
          data: Array.from({ length: 6 }, () => Math.floor(Math.random() * 200) - 100),
          borderColor: '#00AD3C',
          backgroundColor: '#00AD3C',
          pointStyle: 'circle',
          pointRadius: 10,
          pointHoverRadius: 15,
        },
      ],
    };

    const config = {
      type: 'line',
      data: data,
      options: {
        responsive: true,
        maintainAspectRatio: false, // Prevent the chart from maintaining aspect ratio when resizing
        plugins: {
          title: {
            display: true,
          },
        },
      },
    };

    const myChart = new Chart(ctx, config);

    return () => {
      myChart.destroy();
    };
  }, []);

  

  return (
    <div style={{ width: '100%', height: '400px' }}>
      <canvas ref={chartRef} style={{ width: '100%', height: '100%' }} />
    </div>
  );
};



export default LineChart;
