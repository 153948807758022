import * as React from 'react';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
const CustomMonthYearPicker = ({ value, onChange, sx = {} }) => {
  const validValue = dayjs.isDayjs(value) ? value : null;
  const maxDate = dayjs().endOf('month');
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DatePicker
        views={['year', 'month']}
        value={validValue}
        onChange={onChange}
        sx={{
          width: '100%',
          backgroundColor: '#fff',
          ...sx,
        }}
        maxDate={maxDate}
      />
    </LocalizationProvider>
  );
};

export default CustomMonthYearPicker;
