import { Box, Grid } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import React from "react";
import { styled } from "@mui/system";
import NoDataImg from "../NoDataFound/NoDataFound";
import CustomPagination from "../Pagnination/Pagnination";

const LeftAlignedCell = styled("div")(({ theme }) => ({
  textAlign: "left",
}));

const TableContainer = ({ rows, columns, pageSize, loading, page, handlePageChange, totalCount, sx }) => {
  const centeredColumns = columns?.map((column) => ({
    ...column,
    headerAlign: column.headerClassName ? undefined : "center",
    cellClassName: column.cellClassName || "centered-cell",
  }));

  return (
    <Box sx={{ width: "100%", overflow: "auto", borderRadius:'10px 10px' }}>
      <Grid container justifyContent="center">
        <Grid item xs={12} sx={{ height: "calc(100vh - 230px)", ...sx, }}>
          <DataGrid
            rows={rows}
            columns={centeredColumns}
            pageSize={pageSize}
            pagination
            paginationMode="server"
            onPageChange={(newPage) => handlePageChange(newPage)}
            rowsPerPageOptions={[10]}
            rowCount={totalCount}
            loading={loading}
            disableColumnResize
            disableColumnFilter
            disableColumnSorting
            disableColumnMenu
            hideFooter
            slots={{ noRowsOverlay: NoDataImg }}
            disableRowSelectionOnClick
            sx={{
              "& .MuiDataGrid-columnHeader": {
                background: "#6ee498",
                borderRadius: "0",
                borderLeft: "0px solid #292D3280",
                fontFamily: "Outfit",
                color:"black",
                "&:first-of-type": {
                  borderLeft: '0px',
                },
                "&:last-of-type": {
                  borderRight: '0px',
                },
                textAlign: "center",
              },
              "& .MuiDataGrid-cell": {
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                borderRadius: "0px",
                border: "1px solid #0000003D",
                fontFamily: "Outfit",
              },
              "& .MuiDataGrid-row": {
                cursor: "pointer",
              },
              "& .leftAlign": {
                textAlign: "left !important",
                justifyContent: "flex-start",
              },
              "& .leftAlignHeader": {
                textAlign: "left !important",
              },
              ".MuiDataGrid-cell:focus": { outline: "none" },
              ".MuiDataGrid-columnHeader:focus": { outline: "none" },
            }}
            components={{
              Cell: (params) => {
                if (params.field === "email") {
                  return <LeftAlignedCell>{params.value}</LeftAlignedCell>;
                }
                return params.value;
              },
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <CustomPagination
            page={page}
            pageSize={pageSize}
            handlePageChange={handlePageChange}
            totalCount={totalCount}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default TableContainer;
