import { Box, Grid, IconButton, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate } from 'react-router-dom';
import CustomSubmitButton from '../../../Components/CustomSubmitButton/CustomSubmitButton';
import CustomDateTimePicker from '../../../Components/CustomDatePicker/CustomDatePicker';
import { useFormik } from 'formik';
import * as Yup from "yup";
import CustomDropDown from '../../../Components/CustomDropDown/CustomDropDown';
import CustomTextField from '../../../Components/CustomTextField/CustomTextField';
import moment from 'moment/moment';
import dayjs from 'dayjs';
const CreateMonthlyGraph = () => {
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const [usersList, setUserList] = useState([
        { id: "1", label: "John Deo", name: "John Deo" },
        { id: "2", label: "Jane Smith", name: "Jane Smith" },
        { id: "3", label: "Michael Johnson", name: "Michael Johnson" },
        { id: "4", label: "Emily Davis", name: "Emily Davis" },
        { id: "5", label: "Robert Brown", name: "Robert Brown" },
        { id: "6", label: "Sophia Martinez", name: "Sophia Martinez" },
        { id: "7", label: "David Wilson", name: "David Wilson" },
        { id: "8", label: "Olivia Taylor", name: "Olivia Taylor" },
        { id: "9", label: "James Anderson", name: "James Anderson" },
        { id: "10", label: "Emma Thomas", name: "Emma Thomas" }
    ])
    const formik = useFormik({
        initialValues: {
            select_Month: dayjs(),
            select_user: 0,
            select_days: []
        },
        validationSchema: Yup.object().shape({
            select_Month: Yup.string().required("Select month is required!"),
            select_user: Yup.string().required("Select user is required!"),
        }),
        onSubmit: async (values, { resetForm }) => {
        }
    })
    const generateDaysForMonth = (monthYear) => {
        const [month, year] = monthYear.split('/').map(Number);
        const momentDate = moment(`${year}-${month}`, 'YYYY-MM');
        const isLeapYear = momentDate.isLeapYear();
        const isFebruary = momentDate.month() === 1;
        const daysInMonth = momentDate.daysInMonth();
        const daysArray = Array.from({ length: daysInMonth }, (_, index) => ({
            day: index + 1,
            percentage: ''
        }));
        if (isLeapYear && isFebruary) {
            daysArray.push({
                day: 29,
                percentage: ''
            });
        }
        formik.setFieldValue('select_days', daysArray);
    };

    useEffect(() => {
        if (formik.values.select_Month) {
            generateDaysForMonth(formik.values.select_Month.format('MM/YYYY'));
        }
    }, [formik.values.select_Month]);

    return (
        <Box sx={{
            width: '100%'
        }}>
            <Box sx={{
                width: "100%",
                boxShadow: "rgba(0, 0, 0, 0.1) 0px 3px 8px",
                top: '0px',
                left: '0px',
                position: 'fixed',
                padding: "12px 12px",
                backgroundColor: '#e6f7ea',
                zIndex: 2
            }}>
                <Grid container spacing={3} alignItems={'center'}>
                    <Grid item xs={2}>
                        <IconButton onClick={() => navigate(-1)} sx={{
                            backgroundColor: "#eee"
                        }}>
                            <ArrowBackIcon />
                        </IconButton>

                    </Grid>
                    <Grid item xs={8}>
                        <Typography sx={{ fontSize: "24px", fontWeight: "500", textAlign: 'center' }}>
                            Add Monthly Details
                        </Typography>
                    </Grid>
                    <Grid item xs={2}>
                        <Box sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'flex-end'
                        }}>
                            <CustomSubmitButton
                                btnTitle="Submit"
                                type="submit"
                                sx={{
                                    width: "120px"
                                }}
                            />
                        </Box>
                    </Grid>
                </Grid>
            </Box>

            <Box sx={{
                maxWidth: { xs: "95%", lg: "80%", xl: "70%" },

                width: "100%",
                p: 3,
                boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                borderRadius: "12px",
                margin: '80px auto 20px auto'
            }}>
                <Grid container spacing={3}>
                    <Grid item xs={6}>
                        <CustomDateTimePicker
                            value={formik.values.select_Month}
                            inputFormat={'MM/YYYY'}
                            onChange={(newValue) => formik.setFieldValue('select_Month', newValue)}
                            placeholder="MM/YYYY" />
                    </Grid>
                    <Grid item xs={6}>
                        <CustomDropDown
                            value={formik.values.select_user}
                            onChange={formik.handleChange}
                            name='select_user'
                            placeholder={"Select User"}
                            menuList={usersList}
                            NoData='No User Available'
                            isLoading={isLoading}
                            getLabel={(item) => item.name}
                            errors={formik.touched.select_user && formik.errors.select_user}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container spacing={3}>
                            {formik?.values?.select_days?.length > 0 && formik.values.select_days?.map((days, index) => {
                                return (
                                    <Grid item xs={12} sm={6} md={4} lg={3} xl={2} key={index}>
                                        <CustomTextField
                                            placeholder={"Enter Percentage"}
                                            style={{ color: "black", fontSize: "16px" }}
                                            label={`Day ${days.day}`}
                                            value={days.percentage}
                                            onChange={(e) => {
                                                const updatedDays = [...formik.values.select_days];
                                                updatedDays[index].percentage = e.target.value;
                                                formik.setFieldValue("select_days", updatedDays);
                                            }}
                                            sx={{
                                                mt: 0,
                                                "& .MuiOutlinedInput-notchedOutline ": {
                                                    outline: "none",
                                                    border: "none",
                                                },
                                                "& .MuiInputBase-root": {
                                                    borderRadius: "12px",
                                                    border: "none",
                                                    color: "black",
                                                    fontSize: "16px",
                                                    background: "white",
                                                    fontFamily: "Outfit",
                                                },
                                            }}
                                        />
                                    </Grid>
                                )
                            })
                            }
                        </Grid>
                    </Grid>
                </Grid>
            </Box>
        </Box>
    );
};

export default CreateMonthlyGraph;