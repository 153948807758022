import { Box, Typography } from "@mui/material";
import React, { useState } from "react";
import CustomDropDown from "../../../Components/CustomDropDown/CustomDropDown";
import { useNavigate } from "react-router-dom";
import TableContainer from "../../../Components/TableContainer/TableContainer";
import CustomAction from "../../../Components/CustomAction/CustomAction";

const WithdrawFunds = () => {
  const navigate = useNavigate();
  const [category, setCategory] = useState(0);
  const pageSize = 30;
  const [page, setPage] = useState(0);
  const [count, setCount] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const filterCategory = [
    {
      id: 1,
      name: "All",
    },
    {
      id: 2,
      name: "Pending",
    },
    {
      id: 3,
      name: "Accepted",
    },
    {
      id: 4,
      name: "Waiting",
    },
    {
      id: 5,
      name: "Rejected",
    },
    {
      id: 6,
      name: "Cancelled By User",
    },
    {
      id: 7,
      name: "Paid",
    },
  ];

  const row = [
    {
      id: 1,
      rowid: 1,
      name: "Shah Rohan",
      email: "rohan.shah@example.com",
      date: "2024-08-01",
      txn: "TXN001",
      amount: 1500.75,
      note: "Lorem ipsum dolor sit amet.",
    },
    {
      id: 2,
      rowid: 2,
      name: "Patel Kavya",
      email: "kavya.patel@example.com",
      date: "2024-08-02",
      txn: "TXN002",
      amount: 2500.0,
      note: "Lorem ipsum dolor sit amet.",
    },
    {
      id: 3,
      rowid: 3,
      name: "Mehta Arjun",
      email: "arjun.mehta@example.com",
      date: "2024-08-03",
      txn: "TXN003",
      amount: 350.5,
      note: "Lorem ipsum dolor sit amet.",
    },
    {
      id: 4,
      rowid: 4,
      name: "Joshi Priya",
      email: "priya.joshi@example.com",
      date: "2024-08-04",
      txn: "TXN004",
      amount: 1200.25,
      note: "Lorem ipsum dolor sit amet.",
    },
    {
      id: 5,
      rowid: 5,
      name: "Desai Vishal",
      email: "vishal.desai@example.com",
      date: "2024-08-05",
      txn: "TXN005",
      amount: 1800.0,
      note: "Lorem ipsum dolor sit amet.",
    },
  ];

  const columns = [
    { field: "rowid", headerName: "Sr.No.", width: 80 },
    {
      field: "date",
      headerName: "Date",
      minWidth: 180,
    },
    {
      field: "txn",
      headerName: "TXN",
      minWidth: 180,
    },
    {
      field: "name",
      headerName: "Name",
      minWidth: 180,
    },
    {
      field: "email",
      headerName: "Email",
      flex: 1,
      minWidth: 180,
    },
    {
      field: "amount",
      headerName: "Amount",
      minWidth: 150,
    },
    {
      field: "note",
      headerName: "Note",
      minWidth: 200,
    },
    {
      field: "status",
      headerName: "Status",
      minWidth: 180,
      renderCell: (params) => {
        return (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: "100%",
              gap: "2rem",
            }}
          >
            Status
          </Box>
        );
      },
    },
    {
      field: "actions",
      headerName: "Action",
      minWidth: 200,
      renderCell: (params) => {
        return (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: "100%",
              gap: "2rem",
            }}
          >
            <CustomAction
              showBlock={true}
              showUnBlock={true}
              showDelete={true}
            />
          </Box>
        );
      },
    },
  ];
  const handlePageChange = (newPage) => {
    setPage(newPage);
  };
  return (
    <>
      <Box
        sx={{
          width: "100%",
          bgcolor: "rgb(230, 247, 234)",
        }}
      >
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "end",
          }}
        >
          <Box sx={{ width: "100%" }}>
            <Typography sx={{ fontSize: "24px", fontWeight: "500" }}>
              Withdraw Funds
            </Typography>
          </Box>
          <Box sx={{ width: "100%", display: "flex", justifyContent: "end" }}>
            <CustomDropDown
              sx={{ width: "250px" }}
              placeholder={"Filter Category"}
              menuList={filterCategory}
              value={category}
              onChange={(e) => setCategory(e.target.value)}
            />
          </Box>
        </Box>
        <Box
          sx={{
            width: "100%",
            py: 5,
          }}
        >
          <TableContainer
            rows={row}
            columns={columns}
            pageSize={pageSize}
            loading={isLoading}
            page={page}
            totalCount={count}
            handlePageChange={handlePageChange}
          />
        </Box>
      </Box>
    </>
  );
};

export default WithdrawFunds;
