import { Box, Typography } from "@mui/material";
import React, { useState } from "react";
import CustomDropDown from "../../../Components/CustomDropDown/CustomDropDown";
import { useNavigate } from "react-router-dom";
import TableContainer from "../../../Components/TableContainer/TableContainer";
import Document from "../../../Assets/Images/Common/updated-aadhaar.png";
const FundsDeposit = () => {
  const navigate = useNavigate();
  const [category, setCategory] = useState(0);
  const pageSize = 30;
  const [page, setPage] = useState(0);
  const [count, setCount] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const filterCategory = [
    {
      id: 1,
      name: "All",
    },
    {
      id: 2,
      name: "Pending",
    },
    {
      id: 3,
      name: "Accepted",
    },
    {
      id: 4,
      name: "Waiting",
    },
    {
      id: 5,
      name: "Rejected",
    },
    {
      id: 6,
      name: "Cancelled By User",
    },
    {
      id: 7,
      name: "Paid",
    },
  ];

  const row = [
    {
      id: 1,
      rowid: 1,
      name: "Shah Rohan",
      email: "rohan.shah@example.com",
      request_date: "2024-08-01",
      txn: "TXN001",
      amount: 1500.75,
    },
    {
      id: 2,
      rowid: 2,
      name: "Patel Kavya",
      email: "kavya.patel@example.com",
      request_date: "2024-08-02",
      txn: "TXN002",
      amount: 2500.00,
    },
    {
      id: 3,
      rowid: 3,
      name: "Mehta Arjun",
      email: "arjun.mehta@example.com",
      request_date: "2024-08-03",
      txn: "TXN003",
      amount: 350.50,
    },
    {
      id: 4,
      rowid: 4,
      name: "Joshi Priya",
      email: "priya.joshi@example.com",
      request_date: "2024-08-04",
      txn: "TXN004",
      amount: 1200.25,
    },
    {
      id: 5,
      rowid: 5,
      name: "Desai Vishal",
      email: "vishal.desai@example.com",
      request_date: "2024-08-05",
      txn: "TXN005",
      amount: 1800.00,
    },
  ];
  
  const columns = [
    { field: "rowid", headerName: "Sr.No.", minWidth: 100 },
    {
      field: "request_date",
      headerName: "Request Date",
      minWidth: 200,
    },
    {
      field: "txn",
      headerName: "TXN",
      minWidth: 200,
    },
    {
      field: "name",
      headerName: "Name",
      minWidth: 200,
    },
    {
      field: "email",
      headerName: "Email",
      flex: 1,
      minWidth: 200,
    },
    {
      field: "amount",
      headerName: "Amount",
      minWidth: 200,
    },
    {
      field: "dipositimage",
      headerName: "Diposit Image",
      minWidth: 200,
      renderCell: (params) => (
        <Box
          sx={{
            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img
            src={Document}
            alt="document"
            style={{ width: "40px", height: "40px" }}
          />
        </Box>
      ),
    },
    {
      field: "status",
      headerName: "Status",
      minWidth: 200,
      renderCell: (params) => (
        <Box
          sx={{
            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          status
        </Box>
      ),
    },
    
  ];
  const handlePageChange = (newPage) => {
    setPage(newPage);
  };
  return (
    <>
      <Box
        sx={{
          width: "100%",
          bgcolor: "rgb(230, 247, 234)",
        }}
      >
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "end",
          }}
        >
          <Box sx={{ width: "100%" }}>
            <Typography sx={{ fontSize: "24px", fontWeight: "500" }}>
              Funds Deposit
            </Typography>
          </Box>
          <Box sx={{ width: "100%", display: "flex", justifyContent: "end" }}>
            <CustomDropDown
              sx={{ width: "250px" }}
              placeholder={"Filter Category"}
              menuList={filterCategory}
              value={category}
              onChange={(e) => setCategory(e.target.value)}
            />
          </Box>
        </Box>
        <Box
          sx={{
            width: "100%",
            py:5
          }}
        >
          <TableContainer
            rows={row}
            columns={columns}
            pageSize={pageSize}
            loading={isLoading}
            page={page}
            totalCount={count}
            handlePageChange={handlePageChange}
          />
        </Box>
      </Box>
    </>
  );
};

export default FundsDeposit;
