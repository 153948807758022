import React from "react";
import { useDropzone } from "react-dropzone";
import { Box, IconButton, Typography } from "@mui/material";

import CloseIcon from "../../Assets/Images/Common/delete.svg";
import { toast } from "react-toastify";
import Skeleton from "react-loading-skeleton";
import CloudIcon from "@mui/icons-material/Cloud";
const CustomThumbUpload = ({
  onDrop,
  accept,
  selectedFile,
  onRemove,
  isLoading,
  errors,
  sx = {},
}) => {
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept,
    onDropRejected: (rejectedFiles) => {
      if (rejectedFiles.length > 0) {
        toast.error("Invalid file type for thumb!");
      }
    },
  });

  return (
    <Box
      sx={{
        textAlign: "center",
        p: isLoading || selectedFile ? "0px" : "0px",
        borderRadius: 4,
        border: errors ? "1px solid red" : "1px solid #BB9EFF",
        position: "relative",
        height: "125px",
        ...sx,
      }}
    >
      {isLoading ? (
        <Skeleton height={120} borderRadius={20} />
      ) : !selectedFile ? (
        <div
          {...getRootProps()}
          style={{
            cursor: "pointer",
            padding: "20px",
            borderRadius: "8px",
            height: "125px",
          }}
        >
          <input {...getInputProps()} />
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <CloudIcon
              sx={{
                color: "white",
                bgcolor: "black",
                width: "40px",
                height: "40px",
                p: 0.5,
                borderRadius: "8px",
              }}
            />
            <Typography
              variant="body1"
              color="black"
              sx={{
                mt: 1,
                textAlign: "center",
                px: { xs: 2, sm: 3, md: 3, lg: 10, xl: 10 },
                fontWeight: "500",
              }}
            >
              Upload Image
            </Typography>
            <Typography
              variant="body1"
              color="black"
              sx={{
                mt: 1,
                textAlign: "center",
                px: { xs: 2, sm: 3, md: 3, lg: 10, xl: 10 },
                fontWeight: "500",
              }}
            >
              <span style={{ color: "#ddd" }}>(JPG,JPEG,PNG,WEBP)</span>
            </Typography>
          </Box>
        </div>
      ) : (
        <Box
          sx={{
            width: "100%",
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            position: "relative",
            margin: "0 auto",
          }}
        >
          <Box
            sx={{
              width: "350px",
              height: "100%",
            }}
          >
            <img
              src={selectedFile}
              alt="thumb"
              style={{
                width: "100%",
                borderRadius: "10px",
                height: "100%",
              }}
            />
          </Box>
          <IconButton
            onClick={onRemove}
            sx={{
              position: "absolute",
              top: "0px",
              right: "0px",
              textTransform: "none",
            }}
          >
            <img src={CloseIcon} alt="delete" />
          </IconButton>
        </Box>
      )}
    </Box>
  );
};

export default CustomThumbUpload;
