import { Box, MenuItem, Select, Typography } from "@mui/material";
import Skeleton from "react-loading-skeleton";
const CustomDropDown = ({
  disabled,
  value,
  onChange,
  NoData,
  menuList,
  placeholder,
  sx = {},
  style = {},
  errors,
  Title,
  isLoading,
  getLabel,
  name
}) => {
  return (
    <>
      <Box sx={{ display: "flex", flexDirection: "column", gap: "0px" }}>
        {Title && (
          <Typography
            sx={{
              color: "black",
              fontWeight: "400",
              textAlign: "left",
              fontSize: { xs: "14px", sm: "16px" },
              ...style,
            }}
          >
            {Title}
          </Typography>
        )}
        {isLoading ? (
          <Skeleton height={"60px"} />
        ) : (
          <Select
            sx={{
              width: "100%",
              height: "56px",
              border: errors ? "1px solid red" : "none",
              borderRadius: "10px",
              background:'white',
              "&:focus": {
                outline: "none",
                boxShadow: "none",
              },
              "&:hover": {
                outline: "none",
              },
              ".MuiOutlinedInput-notchedOutline": {
                border: errors ? "1px solid red" : "none",
              },
              ...sx,
            }}
            name={name}
            value={value}
            onChange={onChange}
            displayEmpty
            disabled={disabled}
          >
             
            <MenuItem value={0} disabled>
              {placeholder}
            </MenuItem>
            {menuList ? (
              menuList.map((elem, index) => (
                <MenuItem key={index} value={elem.id}>
                  {getLabel ? elem?.name : elem?.name}
                </MenuItem>
              ))
            ) : (
              <Typography>{NoData}</Typography>
            )}
          </Select>
        )}
        {errors && (
          <Typography
            sx={{
              color: "red",
              fontWeight: "400",
              fontSize: { xs: "14px", sm: "14px" },
              marginTop: "5px",
            }}
          >
            {errors}
          </Typography>
        )}
      </Box>
    </>
  );
};

export default CustomDropDown;
