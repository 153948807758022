import {
  Box,
  Button,
  Container,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import React, { useCallback, useState } from "react";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import CustomTextField from "../../../Components/CustomTextField/CustomTextField";
import CustomThumbUpload from "../../../Components/CustomFileUpload/UploadImage";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import PhoneInput from "react-phone-input-2";
const AddCustomer = () => {
  const navigate = useNavigate();
  const [selectedThumb, setSelectedThumb] = useState(null);
  const [selectedThumbFile, setSelectedThumbFile] = useState(null);
  const handleOnChangeThumb = useCallback((acceptedFiles, rejectedFiles) => {
    if (rejectedFiles.length > 0) {
      toast.error("Invalid file type for thumb!");
    } else {
      const file = acceptedFiles[0];
      if (
        file &&
        ["image/jpeg", "image/png", "image/webp", "image/jpg"].includes(
          file.type
        )
      ) {
        if (file.size <= 2 * 1024 * 1024) {
          const videoUrl = URL.createObjectURL(file);
          setSelectedThumbFile(file);
          setSelectedThumb(videoUrl);
          // formik.setFieldValue('thumb', file);
        } else {
          toast.error(`File size exceeds the limit of 2MB`);
        }
      } else {
        toast.error("Upload Only(PNG.JPG,JPEG)");
      }
    }
  }, []);
  return (
    <>
      <Box
        sx={{
          width: "100%",
          bgcolor: "rgb(230, 247, 234)",
        }}
      >
        <Box
          sx={{
            width: "100%",
            display: "flex",
            gap: "30px",
            alignItems: "center",
          }}
        >
          <IconButton onClick={() => navigate("/admin/customermanagement")}>
            <ArrowBackIcon />
          </IconButton>
          <Typography sx={{ fontSize: "24px", fontWeight: "500" }}>
            Add Customer
          </Typography>
        </Box>
        <Container>
          <Box
            sx={{
              width: "100%",
              p: 3,
              mt: 5,
              boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
              borderRadius: "12px",
            }}
          >
            <form
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                gap: "40px",
              }}
            >
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  gap: "40px",
                  flexDirection: { xs: "column", lg: "row" },
                }}
              >
                <Box sx={{ width: "100%" }}>
                  <CustomTextField
                    placeholder={"Enter  Name"}
                    style={{ color: "black", fontSize: "16px" }}
                    label={"Name of customer"}
                    sx={{
                      mt: 0,
                      "& .MuiOutlinedInput-notchedOutline ": {
                        outline: "none",
                        border: "none",
                      },
                      "& .MuiInputBase-root": {
                        borderRadius: "12px",
                        border: "none",
                        color: "black",
                        fontSize: "16px",
                        background: "white",
                        fontFamily: "Outfit",
                      },
                    }}
                  />
                </Box>
                <Box sx={{ width: "100%" }}>
                  <Typography sx={{mb:'8px'}}>Mobile Number</Typography>
                  <PhoneInput
                    country={"us"}
                    name="phno"
                    placeholder="Enter Mobile Number"
                    // value={formik.values.phon_cc + formik.values.phno}
                    onChange={(value, country) => {
                      const countryCodeLength = country.dialCode.length;
                      const phon_cc = value.slice(0, countryCodeLength);
                      const phno = value.slice(countryCodeLength);
                      // formik.setFieldValue("phno", phno);
                      // formik.setFieldValue("phon_cc", phon_cc);
                    }}
                    // onBlur={formik.handleBlur}
                    // onKeyUp={formik.handleChange}
                    inputClass="inputClassCountry"
                    // style={{ border: formik.errors.phno ? "1px solid red" : "none", borderRadius: "8px" }}
                    // isLoading={isLoading}
                  />
                </Box>
              </Box>
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  gap: "40px",
                  flexDirection: { xs: "column", lg: "row" },
                }}
              >
                <Box sx={{ width: "100%" }}>
                  <CustomTextField
                  placeholder={"Enter Email"}
                    style={{ color: "black", fontSize: "16px" }}
                    label={"Email"}
                    sx={{
                      mt: 0,
                      "& .MuiOutlinedInput-notchedOutline ": {
                        outline: "none",
                        border: "none",
                      },
                      "& .MuiInputBase-root": {
                        borderRadius: "12px",
                        border: "none",
                        color: "black",
                        fontSize: "16px",
                        background: "white",
                        fontFamily: "Outfit",
                      },
                    }}
                  />
                </Box>
                <Box sx={{ width: "100%" }}>
                  <CustomTextField
                  placeholder={"Enter Password"}
                    style={{ color: "black", fontSize: "16px" }}
                    label={"Password"}
                    sx={{
                      mt: 0,
                      "& .MuiOutlinedInput-notchedOutline ": {
                        outline: "none",
                        border: "none",
                      },
                      "& .MuiInputBase-root": {
                        borderRadius: "12px",
                        border: "none",
                        color: "black",
                        fontSize: "16px",
                        background: "white",
                        fontFamily: "Outfit",
                      },
                    }}
                  />
                </Box>
              </Box>
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  gap: "40px",
                  flexDirection: { xs: "column", lg: "row" },
                }}
              >
                <Box sx={{ width: "100%" }}>
                  <CustomTextField
                  placeholder={"Enter Document Type"}
                    style={{ color: "black", fontSize: "16px" }}
                    label={"Document Type"}
                    sx={{
                      mt: 0,
                      "& .MuiOutlinedInput-notchedOutline ": {
                        outline: "none",
                        border: "none",
                      },
                      "& .MuiInputBase-root": {
                        borderRadius: "12px",
                        border: "none",
                        color: "black",
                        fontSize: "16px",
                        background: "white",
                        fontFamily: "Outfit",
                      },
                    }}
                  />
                </Box>
                <Box sx={{ width: "100%" }}>
                       
                  <CustomTextField
                  placeholder={"Enter Document Number"}
                    style={{ color: "black", fontSize: "16px" }}
                    label={"Document Number"}
                    sx={{
                      mt: 0,
                      "& .MuiOutlinedInput-notchedOutline ": {
                        outline: "none",
                        border: "none",
                      },
                      "& .MuiInputBase-root": {
                        borderRadius: "12px",
                        border: "none",
                        color: "black",
                        fontSize: "16px",
                        background: "white",
                        fontFamily: "Outfit",
                      },
                    }}
                  />
                </Box>
              </Box>
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  gap: "40px",
                  flexDirection: { xs: "column", lg: "row" },
                }}
              >
                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <CustomThumbUpload
                    accept="image/*"
                    onDrop={handleOnChangeThumb}
                    selectedFile={selectedThumb}
                    onRemove={(e) => {
                      e.stopPropagation();
                      setSelectedThumb(null);
                      setSelectedThumbFile(null);
                      // formik.setFieldValue("thumb", null);
                    }}
                    // typeCheck={typeCheck}
                    // isLoading={isLoading}
                    // errors={formik.errors.thumb}
                    sx={{
                      width: "500px",
                      border: "none",
                      background: "white",
                      height: "200px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  />
                </Box>
              </Box>
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Button
                  sx={{
                    width: "200px",
                    bgcolor: "#6ee498",
                    color: "black",
                    "&:hover": { background: "#6ee498", color: "black" },
                  }}
                >
                  Add Customer
                </Button>
              </Box>
            </form>
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default AddCustomer;
