import React from 'react'

const TermsAndConditions = () => {
  return (
    <div>
      TermsAndConditions
    </div>
  )
}

export default TermsAndConditions
