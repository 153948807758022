import { initializeApp } from "firebase/app";
import { getAuth  } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";
const firebaseConfig = {
  apiKey: "AIzaSyDYw6YZF0gqhAamiQnTDLvCbg6GhFaR_ho",
  authDomain: "transformational-runners.firebaseapp.com",
  projectId: "transformational-runners",
  storageBucket: "transformational-runners.appspot.com",
  messagingSenderId: "1020443363700",
  appId: "1:1020443363700:web:a213572c59920633ed0421"
};
const app = initializeApp(firebaseConfig);
export const AUTH_LOGIN = getAuth(app);
export const DATABASE = getFirestore(app);
export const STORAGE = getStorage(app);