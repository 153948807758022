import { Box, Button, Typography } from "@mui/material";
import React, { useState } from "react";
import CustomSearch from "../../../Components/CustomSearch/CustomSearch";
import CustomDropDown from "../../../Components/CustomDropDown/CustomDropDown";
import TableContainer from "../../../Components/TableContainer/TableContainer";
import CustomAction from "../../../Components/CustomAction/CustomAction";
import Document from "../../../Assets/Images/Common/updated-aadhaar.png";
import { useNavigate } from "react-router-dom";
const CustomerManagement = () => {
  const navigate = useNavigate()
  const pageSize = 30;
  const [category, setCategory] = useState(0);
  const [partnerList, setPartnerList] = useState([]);
  const [page, setPage] = useState(0);
  const [count, setCount] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const userCategory = [
    {
      id: 1,
      name: "All",
    },
    {
      id: 2,
      name: "Individual Customer",
    },
    {
      id: 3,
      name: "Corporate Customer",
    },
    {
      id: 4,
      name: "Test Customer",
    },
  ];
  const row = [
    {
      id: 1,
      rowid: 1,
      name: "Thesiya Yaksh",
      phone: "8200434201",
      email: "yaksh07@gmail.com",
      document: "Addhar",
      documentnumber: "25",
    },
    {
      id: 2,
      rowid: 2,
      name: "Andhariya Avi",
      phone: "8200434201",
      email: "avi@gmail.com",
      document: "Voter ID",
      documentnumber: "25",
    },
    {
      id: 3,
      rowid: 3,
      name: "Pawar Dipak",
      phone: "8200434201",
      email: "Dipak7@gmail.com",
      document: "Driving Licence",
      documentnumber: "25",
    },
    {
      id: 4,
      rowid: 4,
      name: "Rajpurohit Kuldeep",
      phone: "8200434201",
      email: "kuldeep@gmail.com",
      document: "PAN",
      documentnumber: "25",
    },
    {
      id: 5,
      rowid: 5,
      name: "Jadav Vishal",
      phone: "8200434201",
      email: "Vishal@gmail.com",
      document: "Addhar",
      documentnumber: "25",
    },
  ];
  const columns = [
    { field: "rowid", headerName: "Sr.No.", minWidth: 100 },
    {
      field: "name",
      headerName: "Name of the Customer",
      minWidth: 200,
    },
    {
      field: "phone",
      headerName: "Phone number",
      minWidth: 200,
    },
    {
      field: "email",
      headerName: "Email",
      flex: 1,
      minWidth: 200,
    },
    {
      field: "document",
      headerName: "Document",
      minWidth: 200,
    },
    {
      field: "documentnumber",
      headerName: "Document number",
      minWidth: 200,
    },
    {
      field: "documentimage",
      headerName: "Document Image",
      minWidth: 200,
      renderCell: (params) => (
        <Box
          sx={{
            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img
            src={Document}
            alt="document"
            style={{ width: "40px", height: "40px" }}
          />
        </Box>
      ),
    },
    {
      field: "action",
      headerName: "Actions",
      minWidth: 200,
      renderCell: (params) => {
        return (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: "100%",
              gap: "2rem",
            }}
          >
            <CustomAction showDelete={true} showEdit={true} showView={true} />
          </Box>
        );
      },
    },
  ];
  const handlePageChange = (newPage) => {
    setPage(newPage);
  };
  return (
    <>
      <Box
        sx={{
          width: "100%",
          bgcolor: "rgb(230, 247, 234)",
        }}
      >
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography sx={{ fontSize: "24px", fontWeight: "500" }}>
            Customer Management
          </Typography>
          <Button
          onClick={() => navigate("/admin/addcustomer")}
            sx={{
              bgcolor: "#6ee498",
              color: "black",
              "&:hover": { background: "#6ee498", color: "black" },
            }}
          >
            + Add Customer
          </Button>
        </Box>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "end",
            py: 2,
          }}
        >
          <Box sx={{ width: "100%" }}>
            <CustomSearch placeholder={"Search"} style={{maxWidth:"350px"}}  />
          </Box>
          <Box sx={{ width: "100%", display: "flex", justifyContent: "end" }}>
            <CustomDropDown
              sx={{ width: "250px" }}
              placeholder={"Select Category"}
              menuList={userCategory}
              value={category}
              onChange={(e) => setCategory(e.target.value)}
            />
          </Box>
        </Box>
        <Box
          sx={{
            width: "100%",
          }}
        >
          <TableContainer
            rows={row}
            columns={columns}
            pageSize={pageSize}
            loading={isLoading}
            page={page}
            totalCount={count}
            handlePageChange={handlePageChange}
          />
        </Box>
      </Box>
    </>
  );
};

export default CustomerManagement;
